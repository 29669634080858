"use strict";
/// <reference types="swiper"/>
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwiperSlider = void 0;
require("core-js/stable/object/assign");
var swiper_1 = __importStar(require("swiper"));
var data_1 = require("../utils/data");
require("swiper/scss");
require("swiper/scss/navigation");
require("swiper/scss/thumbs");
var SwiperSlider = /** @class */ (function () {
    function SwiperSlider(config) {
        this.options = {};
        this.element = config.element;
        Object.assign(this.options, this.optionsFromDataAttributes());
        if (config.options) {
            Object.assign(this.options, config.options);
        }
        this.loadModules();
    }
    SwiperSlider.prototype.optionsFromDataAttributes = function () {
        var options = {};
        Object.assign(options, this.element.dataset);
        for (var optionsKey in options) {
            options[optionsKey] = data_1.Data.getData(options[optionsKey]);
        }
        return options;
    };
    SwiperSlider.prototype.loadModules = function () {
        if (!this.options.hasOwnProperty('modules')) {
            this.options.modules = [];
        }
        for (var optionsKey in this.options) {
            if (optionsKey === 'autoplay') {
                this.options.modules.push(swiper_1.Autoplay);
            }
            if (optionsKey === 'lazy') {
                this.options.modules.push(swiper_1.Lazy);
            }
            if (optionsKey === 'navigation') {
                this.options.modules.push(swiper_1.Navigation);
            }
            if (optionsKey === 'pagination') {
                this.options.modules.push(swiper_1.Pagination);
            }
            if (optionsKey === 'thumbs') {
                this.options.modules.push(swiper_1.Thumbs);
            }
        }
        return;
    };
    SwiperSlider.prototype.getInstance = function () {
        return this.instance;
    };
    SwiperSlider.prototype.run = function () {
        this.instance = new swiper_1.default(this.element, this.options);
        return this;
    };
    return SwiperSlider;
}());
exports.SwiperSlider = SwiperSlider;
